/**
 * 代理详情
 */
import dayjs from "dayjs";
import * as Service from "@/service/auth";
import { ExpressesEmit, affirmShipments, checkSourceCode } from "@/service/order";
import { Dialog, Toast } from "vant";
import { mapState } from "vuex";
import { scancode } from "@/utils/wx-promise";
import logisticsPopup from "@/components/order/logistics-popup/index.vue";
import { getUrlParams } from "@/utils/common.js";
import { createRetailSuborder } from "@/service/order/index";
import { getToken } from "@/utils/localStorage.js";

export default {
  name: "order-dispatch-terminal",

  components: { logisticsPopup },

  data() {
    return {
      showCompanyChoose: false,   // 是否展示物流公司选择
      companyList: [],    //物流公司列表
      logisticsCompony: {},   // 物流公司
      currentIndex: -1,   // 物流公司的下标
      logisticsCode: "",   // 物流单号
      sourceCode: "",   // 溯源的码
      sourceCodeList: [], //溯源码列表
      row: {}, //当前正在操作的审核订单
      detail: {},
      showDetail: false, //是否展示个人信息的弹窗
      operarow: {}, //当前操作的列
      reason: "",
      rejectShow: false, //reject弹出层
      showList: [],
      finished: false,
      offset: 0, //页数，0开始
      limit: 10, //每页的调数
      loading: false,
      systemList: [], //用户的升级列表
      canUpgrade: false, // 能否给当前代理升级
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.user.user.id, // 我的ID
      address: (state) => state.orderDispatch.addressInfo,
      junior: (state) => state.orderDispatch.junior,
      products: (state) => state.orderDispatch.productList,
      totalPrice: (state) => state.orderDispatch.totalPrice,
    }),
  },

  methods: {
    // 展示物流公司的选择
    toShowCompanyChoose() {
      this.showCompanyChoose = true;
      this.currentIndex = 0;   // 清空选择
    },

    // popup中物流公司的改动
    onCompanyChange(e, value, index) {
      this.currentIndex = index;
    },

    //确定物流公司
    confirmCompany(e, value, index) {
      this.showCompanyChoose = false;
      this.logisticsCompony = this.companyList[this.currentIndex];
      // 选择
    },

    // 获取物流单号
    async getLogisticsCode() {
      let code = await scancode();
      this.logisticsCode = code.split(",")[1];   // 第二个才是
    },

    // 获取溯源码
    async getLogisticsSource() {
      let code = await scancode();
      console.log("code", code);
      const params = getUrlParams(code);
      if (code.indexOf("?") >= 0 && params["fw"]) {
        //  加入判断，如果识别出来的是网址，解析网址 http://cx.1.net/?fw=9834085332679670
        this.sourceCode = params["fw"];
      } else {
        this.sourceCode = code.split(",")[1] || code;
      }
    },

    // 将溯源码添加到朔源码列表中
    async addSourceCode() {
      const code = this.sourceCode && this.sourceCode.trim();
      if (!this.validateSourceCode(this.sourceCode && this.sourceCode.trim())) {
        Toast("该溯源码不是12位或者16位不能发货");
        return false;
      }
      if (this.sourceCode.trim()) {
        // 校验朔源码是否有效，如果有，则添加
        if (
          this.sourceCodeList.find((e) => {
            return e.sourceCode === this.sourceCode;
          })
        ) {
          Toast("该溯源码已经在列表中");
          return;
        }
        this.sourceCodeList.push({
          sourceCode: this.sourceCode,
          size: `1箱`,
          type: "1",
        });
      }
    },

    // 校验溯源码是否正确
    validateSourceCode(sourceCode) {
      if (!/^(\d{16}|\d{12})$/.test(sourceCode)) {
        Toast(`存在溯源码${sourceCode}不是12位数字或者16位数字，不能发货～`);
        return false;
      }
      return true;
    },

    // 切换是否全显订单详情
    toggleDetail() { },

    // 确定订单
    async send() {
      const { userId, address, junior, products, logisticsCompony, logisticsCode } = this;
      if (this.sourceCodeList.length == 0 || !this.sourceCodeList) {
        Toast("至少需要一个溯源码");
        return;
      }
      if (!this.logisticsCode) {
        Toast("物流单号不能为空");
        return;
      } else {
        try {
          await Dialog.confirm({ message: `确定发货吗～` });
          const localdate = new Date();
          let str = dayjs(localdate).format("YYYY-MM-DD HH:mm:ss");
          let sourceList = {};
          this.sourceCodeList.forEach((e) => {
            sourceList[e.sourceCode] = e.type;
          });

          await createRetailSuborder({
            userId: userId,
            addressInfo: address,
            productList: products, 
            sourceCode: sourceList,
            juniorId: junior.userId,
            trackCompany: logisticsCompony.name,
            trackingNumber: logisticsCode,
          }).then((res) => {
            this.$router.push({
              name: "retail-delivery-success",
              query: { orderId: res.code },
            });
          });
        } catch (error) {
          console.log(error);
        }
      }
    }

  },

  async mounted() {
    const data = await ExpressesEmit();
    this.companyList = data;
  },
};
